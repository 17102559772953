<template lang="pug">
.form-modal(v-if="showFormManager.scratchCardSettings" @mousedown="cancel")
  .content.gamification-settings(@mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .button.om-close(@click="cancel" style="position: absolute; top: 0; right: 0") &times;
      .om-row.header-inner
        .om-col.om-col-7
          .om-title-field {{ $t('scratchCard.settings.title') }}
    table
      thead
        tr.om-modal-tr.om-modal-tr-title
          th.om-modal-wheel-th(v-for="key in titleOptions" style="text-align: left") {{ $t(key) }}
      tbody.om-table-scroll.om-table-scroll-wheel
        tr.om-modal-tr.om-modal-wheel-tr(v-for="(row, index) in options")
          td.om-modal-wheel-td
            input.simpleInput.wheelInput(
              :class="{ 'invalid-field': isAllZeroed && !index }"
              type="number"
              step="1"
              min="0"
              v-model="options[index].weigth"
              @input="onWeightInput($event, index)"
            )
          td.om-modal-wheel-td
            div {{ probability(row.weigth) }}%
          td.om-modal-wheel-td
            input(
              :class="classes(index, 'title')"
              :disabled="disabled(index)"
              v-model="options[index].title"
            )
          td.om-modal-wheel-td
            input(
              :class="classes(index, 'couponCode')"
              :disabled="disabled(index)"
              v-model="options[index].couponCode"
            )
    .om-row.footer(style="position: relative")
      .om-col.om-col-2
        .button.button-link(@click="cancel") {{ $t('cancel') }}
      .om-col.om-col-3
        .button.button-primary.button-large(@click="save") {{ $t('save') }}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import weighting from '@/editor/mixins/weighting';

  const _c = (v) => JSON.parse(JSON.stringify(v));

  export default {
    mixins: [weighting],
    data: () => ({
      titleOptions: ['weightingFactor', 'chanceToWin', 'title', 'couponCode'],
      selectedField: null,
      percent: 1,
      options: [],
    }),
    computed: {
      ...mapState(['showFormManager', 'scratchCardOptions']),
    },
    watch: {
      scratchCardOptions(n) {
        this.options = _c(n);
      },
    },
    mounted() {
      this.options = _c(this.scratchCardOptions);
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      save() {
        this.saveBtnClicked = true;
        if (this.isActiveSave()) {
          this.hideFrom('save', 'scratchCardSettings');
          this.$bus.$emit('setWorkspaceStateAttr', {
            attr: 'scratchCardOptions',
            stateAttr: 'template',
            value: _c(this.options),
          });
          this.$store.state.scratchCardOptions = _c(this.options);
        }
      },
      cancel() {
        this.saveBtnClicked = false;
        this.hideFrom('cancel', 'scratchCardSettings');
        this.options = _c(this.scratchCardOptions);
      },
      onWeightInput(e, index) {
        const isInt = e.target.value.match(/\d+/g);
        const newValue = isInt ? isInt[0] : 0;
        this.options[index].weigth = parseInt(newValue, 10);
      },
    },
  };
</script>

<style scoped>
  .selected-custom-field td {
    color: #ed5a29;
  }
  .invalid-field {
    border-color: #ff9494;
    background-color: #ff9494;
  }
  .disabled {
    background: #e8eaec;
  }
</style>
